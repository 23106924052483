@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/fonts" as *;
@use "../../styles/partials/global" as *;

.contact {
  padding: 20px;
  background-color: $secondary;

  @include tablet {
    padding: 20px 50px;
  }

    @include desktop {
    padding: 20px 100px;
  }

  &__subhead {
    margin: 20px 0;
  }

  &__icons-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 20px;
    width: 100%;
  }
}
