* {
  font-family: "Roboto", sans-serif;
}

@font-face {
  font-family: "Roboto";
  src: url("../../assets/fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("../../assets/fonts/Roboto-Light.ttf") format("truetype");
  font-weight: 200;
  font-style: light;
}

@font-face {
  font-family: "Roboto";
  src: url("../../assets/fonts/Roboto-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: bold;
}

