@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/fonts" as *;
@use "../../styles/partials/global" as *;

.about {
  padding: 20px;
  background-color: $secondary;

  @include tablet {
    padding: 20px 50px;
  }

    @include desktop {
    padding: 20px 100px;
  }

  &__intro {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }

  &__left {
    width: 50%;

    @include tablet {
        width: 30%;
    }

    @include desktop {
        width: 30%;
    }
  }

  &__right {
    width: 45%;
  }

  &__headshot {
    border-radius: 50%;
    width: 100%;
  }

  &__bio {
    text-align: left;
    padding: 20px 0;

    @include tablet {
      text-align: center;
 
    }

    @include desktop {
          text-align: center;
    }
  }
}
