@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/fonts" as *;
@use "../../styles/partials/global" as *;

.header {
  background-color: $primary;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1000;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

  @include tablet {
    padding: 20px 50px;
  }

  @include desktop {
    padding: 20px 100px;
  }

  &__left {
    display: flex;
    flex-direction: column;
    align-items: start;
    text-align: left;
  }

  &__list {
    display: flex;

    list-style: none;
  }

  &__list-item {
    margin: 0 5px;
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    font-size: 12px;

    @include tablet {
      font-size: 16px;
      margin: 0 10px;
    }

    @include desktop {
      font-size: 16px;
      margin: 0 10px;
    }

    &:hover {
      background-color: $secondary;
    }
  }

  &__name {
    color: white;
  }

  &__sub {
    color: white;
  }
}
